import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import CompanyProfile from './CompanyProfile.jsx';
import { formatField } from '../../../partials/job/JobListItem';
import { formatDateToShortDate, fromNow } from '../../../utils/Utils';

import { createApplication } from '../../../common/services/app';
import { PiStudentDuotone } from 'react-icons/pi';

import { IoCloseSharp, IoDocumentOutline } from 'react-icons/io5';
import { FaCheck, FaRegMoneyBillAlt } from 'react-icons/fa';
import { HiOutlineClock } from 'react-icons/hi';
import { RiMapPinTimeLine } from 'react-icons/ri';

import ModalBlank from '../../../components/ModalBlank';

import GenericModal from '../../..//pages/app/positions/Actions/GenericModal.jsx';
import { modals } from '../../..//pages/app/positions/Actions/modal-enum';

function JobPost({ job, onSubmit: onChange, applications = [] }) {
  const { timeToWork, matchDate, Position } = job;
  const {
    createdAt,
    id: positionId,
    city,
    experience_type,
    experience_level,
    salary_range,
    work_schedule,
    contract_type,
    education_level,
  } = Position;

  const dangerInitialState = { open: false, options: { ...modals['REFUSE_JOB'], service: createApplication } };

  const [dangerModal, setDangerModal] = useState(dangerInitialState);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  function mutateFn(data) {
    const interested = data.interested;
    return toast.promise(createApplication(data), {
      pending: `${interested ? 'Enviando sua candidatura...' : 'Removendo a vaga da sua lista de oportunidades...'}`,
      success: `${interested ? 'Sua candidatura foi enviada com sucesso!' : 'Vaga removida da sua lista de oportunidades!'}`,
      error: `Ocorreu um erro ao ${interested ? 'enviar sua candidatura' : 'remover a vaga da sua lista de oportunidades'}. Por favor, tente novamente.`,
    });
  }

  const { mutate, isLoading } = useMutation(mutateFn, {
    onSuccess: () => {
      setDangerModal(dangerInitialState);
      setSuccessModalOpen(false);
      onChange();
    },
  });

  function onSubmit({ interested }) {
    mutate({
      positionId,
      interested,
    });
  }

  function onInterestClick(e) {
    e.stopPropagation();
    setSuccessModalOpen(true);
  }

  function onNotInterestClick(e) {
    e.stopPropagation();
    setDangerModal((prev) => ({ ...prev, open: true }));
  }

  return (
    <>
      {/* Page content */}
      <div className="pb-12 justify-between max-w-5xl mx-auto lg:grid lg:grid-flow-col grid-cols-[5fr_1fr] xl:space-x-16 lg:py-10">
        {/* Content */}
        <div>
          <header className="mb-3">
            {/* Title */}
            <h2 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mt-5">
              {experience_type}
            </h2>
          </header>
          <div className="text-sm text-slate-500 dark:text-slate-400 italic">
            Publicado em {formatDateToShortDate(createdAt)}
          </div>
          <div className="text-sm text-slate-500 dark:text-slate-400 italic">
            Essa vaga expira em {matchDate ? fromNow(matchDate) : ''}
          </div>

          {/* Company information (mobile) */}
          <div className="bg-white dark:bg-slate-800 p-5 shadow-lg rounded-sm border border-slate-200 mb-6 lg:hidden">
            <div className="space-y-2 sm:flex sm:space-y-0 sm:space-x-2">
              <button className="btn w-full bg-indigo-500 hover:bg-indigo-600 text-white" onClick={onInterestClick}>
                <svg className="w-3 h-3 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                  <FaCheck />
                </svg>
                <span>Tenho interesse!</span>
              </button>
              <button
                className="btn w-full dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                onClick={onNotInterestClick}
              >
                <svg
                  className="w-5 h-5 fill-current text-slate-400 dark:text-rose-600 group-hover:text-rose-500 shrink-0 mr-2"
                  viewBox="0 0 14 14"
                >
                  <IoCloseSharp />
                </svg>
                <span>Não tenho interesse</span>
              </button>
            </div>
          </div>

          <hr className="my-6 border-t border-slate-200 dark:border-slate-700" />

          {/* The Role */}
          {/* <div>
                  <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-2">The Role</h2>
                  <div className="space-y-6">
                    <p>
                      There is so much happening in the AI space. Advances in the economic sectors have seen automated
                      business practices rapidly increasing economic value. While the realm of the human sciences has
                      used the power afforded by computational capabilities to solve many human based dilemmas. Even the
                      art scene has adopted carefully selected ML applications to usher in the technological movement.
                    </p>
                  </div>
                </div> */}

          <div className="flex flex-col gap-3">
            <div className="text-sm">
              <h3 className="flex items-center gap-1 font-medium text-slate-800 dark:text-slate-100">
                <RiMapPinTimeLine /> Cidade
              </h3>
              <div>
                {city} (
                {timeToWork > 0 ? `${timeToWork} minutos do seu endereço` : 'Menos de um minuto do seu endereço'})
              </div>
            </div>
            <div className="text-sm">
              <h3 className="flex items-center gap-1 font-medium text-slate-800 dark:text-slate-100">
                <IoDocumentOutline />
                Experiência desejada
              </h3>
              <div>{experience_level}</div>
            </div>
            <div className="text-sm">
              <h3 className="flex items-center gap-1 font-medium text-slate-800 dark:text-slate-100">
                <PiStudentDuotone />
                Escolaridade mínima
              </h3>
              <div>{education_level}</div>
            </div>
            <div className="text-sm">
              <h3 className="flex items-center gap-1 font-medium text-slate-800 dark:text-slate-100">
                <HiOutlineClock />
                Horário de trabalho
              </h3>
              <div>{work_schedule}</div>
            </div>
            <div className="text-sm">
              <h3 className="flex items-center gap-1 font-medium text-slate-800 dark:text-slate-100">
                <FaRegMoneyBillAlt />
                Remuneração
              </h3>
              <div>
                Salário entre {salary_range} ({formatField(contract_type)})
              </div>
            </div>
          </div>

          {/* <hr className="my-6 border-t border-slate-200 dark:border-slate-700" /> */}

          {/* Things You Might Do */}
          {/* <div>
            <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-2">
              Things You Might Do
            </h2>
            <div className="space-y-6">
              <p>
                We are a fast-growing, and remote-first company, so you'll likely get experience on many different
                projects across the organization. That said, here are some things you'll probably do:
              </p>
              <ul className="list-disc list-inside space-y-1">
                <li>Give back to the community via open source and blog posts</li>
                <li>
                  Travel and meet great people- as part of our remote-first lifestyle, it's important that we come
                  together as needed to work together, meet each other in person and have fun together. Please keep that
                  in mind when you apply.
                </li>
                <li>
                  Teach and be taught: Modus creates active teams that work in internal and external projects together,
                  giving opportunities to stay relevant with the latest technologies and learning from experts worldwide
                </li>
                <li>Interact directly with internal and external clients to represent Modus and its values</li>
              </ul>
            </div>
          </div> */}

          {/* Apply section */}
          <div className="mt-6">
            <p className="font-medium italic mb-6">Gostou da vaga? Candidate-se hoje mesmo!</p>
            <div className="flex justify-between items-center">
              {/* Apply button */}
              <button
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
                onClick={onInterestClick}
              >
                <svg className="w-3 h-3 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                  <FaCheck />
                </svg>
                <span>Tenho interesse!</span>
              </button>
            </div>
          </div>

          {/* Related Jobs */}
          {applications.length > 0 && (
            <>
              <hr className="my-6 border-t border-slate-200 dark:border-slate-700" />
              <CompanyProfile applications={applications} />
            </>
          )}
        </div>

        {/* Sidebar */}
        <div className="hidden lg:block">
          {/* Company information (desktop) */}
          <div className="bg-white dark:bg-slate-800 p-5 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 lg:w-72 xl:w-80">
            <div className="space-y-2">
              <button className="btn w-full bg-indigo-500 hover:bg-indigo-600 text-white" onClick={onInterestClick}>
                <svg className="w-3 h-3 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                  <FaCheck />
                </svg>
                <span>Tenho interesse!</span>
              </button>
              <button
                className="btn w-full dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                onClick={onNotInterestClick}
              >
                <svg
                  className="w-5 h-5 fill-current text-slate-400 dark:text-rose-600 group-hover:text-rose-500 shrink-0 mr-2"
                  viewBox="0 0 14 14"
                >
                  <IoCloseSharp />
                </svg>
                <span>Não tenho interesse</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Success Modal */}
      <ModalBlank id="success-modal" modalOpen={successModalOpen} setModalOpen={setSuccessModalOpen}>
        <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-emerald-100 dark:bg-emerald-400/30">
            <svg className="w-4 h-4 shrink-0 fill-current text-emerald-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">
                Deseja se candidatar a esta vaga?
              </div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>
                  Ao clicar em <b>"Quero me candidatar"</b>, você estará participando do processo seletivo para esta
                  vaga e <b>não poderá se candidatar a outras vagas</b> até que este processo termine.
                </p>
                <p>Não se preocupe, liberamos você para outras vagas caso a empresa não se manifeste em até 72 horas</p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end gap-2">
              <button
                className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                onClick={(e) => {
                  e.stopPropagation();
                  setSuccessModalOpen(false);
                }}
              >
                Cancelar
              </button>
              <button
                disabled={isLoading}
                className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={() => onSubmit({ interested: true })}
              >
                Quero me candidatar
              </button>
            </div>
          </div>
        </div>
      </ModalBlank>
      <GenericModal
        modal={dangerModal}
        onClose={() => setDangerModal(dangerInitialState)}
        onRequest={({ refusal }) => {
          return {
            positionId,
            interested: false,
            ...refusal,
          };
        }}
      />
    </>
  );
}

export default JobPost;
