import ModalBasic from '../../components/ModalBasic';
import React from 'react';
import UseTerms from './UseTerms';
import PrivacyPolicy from './PrivacyPolicy';

export default function Terms({ showTerms, closeModal }) {
  const show = Boolean(showTerms);
  const title = showTerms === 'terms' ? 'Termos de Uso' : 'Política de Privacidade';
  return (
    <ModalBasic id={showTerms || 'terms'} modalOpen={show} setModalOpen={closeModal} title={title} stickyHeader>
      {showTerms === 'terms' ? <UseTerms /> : <PrivacyPolicy />}
      {/* Modal footer */}
      <div className="sticky bottom-0 px-5 py-4 bg-white dark:bg-slate-800 border-t border-slate-200 dark:border-slate-700">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          >
            Fechar
          </button>
        </div>
      </div>
    </ModalBasic>
  );
}
