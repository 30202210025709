import React from 'react';
import Button from '../components/Button';

export default function PaginationLoadMore({ isLoading, hasNextPage, onLoadMore }) {
  const styles = {
    default:
      'leading-5 px-3.5 py-2 bg-white dark:bg-slate-800 hover:bg-indigo-500 dark:hover:bg-indigo-500 border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 hover:text-white',
    disabled:
      'leading-5 px-3.5 py-2 bg-slate-200 dark:bg-slate-700 border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 cursor-not-allowed',
  };

  const style = !hasNextPage || isLoading ? styles.disabled : styles.default;

  return (
    <div className="flex justify-center">
      <nav className="flex" role="navigation" aria-label="Navigation">
        <div className="inline-flex text-sm font-medium -space-x-px shadow-sm">
          <Button className={style} disabled={!hasNextPage} variant="secondary" onClick={onLoadMore}>
            {isLoading ? 'Carregando...' : 'Carregar mais'}
          </Button>
        </div>
      </nav>
    </div>
  );
}
