import React from 'react';
import { formatCpf } from '../../../../common/utils';

export default function PersonalInfoForm({ form, isPaying, onChange }) {
  return (
    <>
      {/* CPF */}
      <div>
        <label className="block text-sm font-medium mb-1" htmlFor="cpf">
          CPF <span className="text-rose-500">*</span>
        </label>
        <input
          disabled={isPaying}
          required
          id="cpf"
          name="cpf"
          type="text"
          inputMode="numeric"
          placeholder="123.456.789-10"
          className="form-input w-full"
          pattern="^\d{3}\.\d{3}\.\d{3}-\d{2}$"
          maxLength={14}
          minLength={14}
          onChange={({ target }) => onChange({ ...form, cpf: formatCpf(target.value) })}
          value={form.cpf}
        />
      </div>
      {/* Email */}
      <div>
        <label className="block text-sm font-medium mb-1" htmlFor="email">
          Email <span className="text-rose-500">*</span>
        </label>
        <input
          id="email"
          type="email"
          required
          disabled={isPaying}
          maxLength={200}
          onChange={({ target }) => onChange({ ...form, email: target.value })}
          className="form-input w-full"
          placeholder="seu@email.com"
        />
      </div>
    </>
  );
}
