import React from 'react';
import DropdownClassic from './DropdownClassic';
import { formatPhone } from '../common/utils';

const defaultValue = {
  id: 'br',
  label: 'Brasil',
  value: '+55',
  number: '',
};

export default function PhoneInput({ value = defaultValue, onChange }) {
  const options = [defaultValue];

  function handleCountry(country) {
    onChange({ ...value, ...country });
  }

  function handlePhone(e) {
    const country = options.find((country) => country.id === value?.id);
    onChange({ ...value, number: formatPhone(e.target.value), country });
  }

  return (
    <div className="grid grid-cols-1 sm:flex gap-3">
      <div>
        <label htmlFor="country" className="block text-sm font-medium mb-1">
          País <span className="text-rose-500">*</span>
        </label>
        <DropdownClassic options={options} value={value?.id} onChange={handleCountry} />
      </div>
      <div>
        <label className="block text-sm font-medium mb-1" htmlFor="email">
          Telefone <span className="text-rose-500">*</span>
        </label>
        <input
          id="phone"
          required
          type="tel"
          value={value?.number}
          maxLength={15}
          onChange={handlePhone}
          placeholder="(00) 90000-0000"
          className="form-input w-full"
        />
      </div>
    </div>
  );
}
