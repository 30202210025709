import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="px-5 py-4 text-sm space-y-6">
      <div>
        <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
          Política de Privacidade do Aplicativo Trabalha AI
        </div>
        <div className="space-y-2">
          <p>
            Esta Política de Privacidade explica como o aplicativo <strong>Trabalha AI</strong>, de propriedade da{' '}
            <strong>Trabalha AI Tecnologia LTDA</strong>, coleta, utiliza, armazena, protege e compartilha os dados
            pessoais dos usuários, em conformidade com a legislação brasileira, incluindo a LGPD, o Código de Defesa do
            Consumidor e o Marco Civil da Internet.
          </p>
        </div>
      </div>

      <div>
        <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">1. Coleta e Uso de Dados</div>
        <div className="space-y-2">
          <p>
            Coletamos informações pessoais como nome, telefone, endereço, CPF, escolaridade, experiência profissional,
            habilidades e outros dados necessários para viabilizar o cadastro e a busca de vagas de emprego.
          </p>
          <p>
            Esses dados são utilizados para facilitar a conexão entre candidatos e empresas, permitindo a oferta e o
            acesso a oportunidades de emprego.
          </p>
        </div>
      </div>

      <div>
        <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
          2. Armazenamento e Segurança dos Dados
        </div>
        <div className="space-y-2">
          <p>
            As informações fornecidas pelos usuários são armazenadas em servidores seguros e protegidos por
            criptografia, visando prevenir acessos não autorizados e garantir a integridade dos dados.
          </p>
          <p>
            Adotamos medidas técnicas e administrativas compatíveis com o nível de sensibilidade dos dados coletados, em
            conformidade com as exigências da LGPD.
          </p>
        </div>
      </div>

      <div>
        <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">3. Compartilhamento de Dados</div>
        <div className="space-y-2">
          <p>
            Os dados pessoais dos usuários poderão ser compartilhados com empresas anunciantes de vagas, exclusivamente
            mediante o consentimento prévio dos titulares.
          </p>
          <p>O compartilhamento será realizado de forma segura e em conformidade com a legislação aplicável.</p>
        </div>
      </div>

      <div>
        <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">4. Direitos dos Usuários</div>
        <div className="space-y-2">
          <p>
            Em conformidade com a LGPD, os usuários têm o direito de acessar, corrigir, atualizar ou solicitar a
            exclusão de seus dados pessoais armazenados pelo aplicativo.
          </p>
          <p>
            Para exercer esses direitos, os usuários poderão entrar em contato por meio dos canais de atendimento
            disponibilizados pelo <strong>Trabalha AI Tecnologia LTDA</strong>.
          </p>
        </div>
      </div>

      <div>
        <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">5. Alterações nesta Política</div>
        <div className="space-y-2">
          <p>
            Esta Política de Privacidade pode ser atualizada periodicamente. As alterações serão comunicadas aos
            usuários através de notificações no aplicativo, e-mail ou mensagem de texto, conforme as informações de
            contato fornecidas.
          </p>
          <p>
            Recomendamos que os usuários revisem periodicamente esta política para se manterem informados sobre como
            protegemos suas informações.
          </p>
        </div>
      </div>

      <div>
        <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">6. Contato</div>
        <div className="space-y-2">
          <p>
            Para dúvidas, esclarecimentos ou para exercer quaisquer dos direitos previstos nesta política, entre em
            contato com o <strong>Trabalha AI Tecnologia LTDA</strong> através dos canais de atendimento
            disponibilizados no aplicativo.
          </p>
        </div>
      </div>
    </div>
  );
}
