import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../common/hooks/useUser.jsx';
import Select from '../../../components/Select';
import { formatCpf } from '../../../common/utils.ts';
import { isValid } from '@fnando/cpf';

function CandidatePersonalInfo({ display, onSubmit, onPrev }) {
  const [isValidCpf, setIsValidCpf] = useState(true);
  const { enumData: data } = useContext(UserContext);
  const options = data.formPositions;

  function handleFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    onSubmit({
      name: formData.get('name'),
      cpf: formData.get('cpf'),
      education_level: formData.get('education_level'),
    });
  }

  return (
    <div className="px-4 py-8" style={{ display: display ? 'block' : 'none' }}>
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Dados pessoais</h1>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">
          Agora precisamos de algumas informações suas para te apresentar às empresas. Fique tranquilo, seus dados estão
          seguros conosco.
        </p>
        {/* htmlForm */}
        <form onSubmit={handleFormSubmit}>
          <div className="space-y-4 mb-3">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Nome <span className="text-rose-500">*</span>
              </label>
              <input
                required
                id="name"
                name="name"
                type="text"
                minLength="3"
                maxLength="100"
                className="form-input w-full"
              />
              <div className="text-xs mt-1">Pra gente saber como te chamar e para te apresentar às empresas</div>
            </div>
          </div>

          <div className="space-y-4 mb-3">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                CPF <span className="text-rose-500">*</span>
              </label>
              <input
                required
                type="text"
                id="cpf"
                inputMode="numeric"
                onChange={({ target: t }) => {
                  t.value = formatCpf(t.value);
                  const valid = isValid(t.value);
                  t.setCustomValidity('');
                  if (!valid) {
                    t.setCustomValidity('CPF inválido. Verifique o número digitado.');
                  }
                }}
                onBlur={({ target: t }) => {
                  setIsValidCpf(isValid(t.value));
                }}
                name="cpf"
                pattern="^\d{3}\.\d{3}\.\d{3}-\d{2}$"
                maxLength={14}
                minLength={14}
                className={`form-input w-full ${isValidCpf ? '' : 'border-rose-300'} `}
              />
              {!isValidCpf && <div className="text-xs mt-1 text-rose-500">Por favor, insira um CPF válido</div>}
            </div>
          </div>

          <div className="space-y-4 mb-3">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="education_level">
                Escolaridade <span className="text-rose-500">*</span>
              </label>

              <Select id="education_level" name="education_level" required options={options.education_level} />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Link className="text-sm underline hover:no-underline" onClick={onPrev}>
              &lt;- Voltar
            </Link>
            <button type="submit" className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto">
              Prosseguir -&gt;
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CandidatePersonalInfo;
