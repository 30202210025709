import Invoices from './InvoicesTableItem';
import React, { useState, useEffect } from 'react';
function InvoicesTable({ data }) {
  const { invoices } = data;
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(invoices);
  }, [invoices]);

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <div className="bg-white dark:bg-slate-800">
      {/* Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full dark:text-slate-300">
          {/* Table header */}
          <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
            <tr>
              <th className="px-1 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
                <div className="font-semibold text-left">ID</div>
              </th>
              <th className="px-1 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
                <div className="font-semibold text-left">Total</div>
              </th>
              <th className="px-1 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
                <div className="font-semibold text-left">Status</div>
              </th>
              <th className="px-1 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
                <div className="font-semibold text-left">Vaga</div>
              </th>
              <th className="px-1 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
                <div className="font-semibold text-left">Data de criação</div>
              </th>
              <th className="px-1 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
                <div className="font-semibold text-left">Data de pagamento</div>
              </th>
              <th className="px-1 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
                <div className="font-semibold text-left">Método de pagamento</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
            {list?.map((invoice) => {
              return (
                <Invoices
                  key={invoice.id}
                  id={invoice.id}
                  invoice={invoice.invoice}
                  itemCode={invoice.itemCode}
                  total={invoice.total}
                  status={invoice.status}
                  customer={invoice.customer}
                  issueddate={invoice.issueddate}
                  paiddate={invoice.paiddate}
                  type={invoice.type}
                  handleClick={handleClick}
                  isChecked={isCheck.includes(invoice.id)}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InvoicesTable;
