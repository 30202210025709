import React, { useState, memo } from 'react';

import JobSidebar from '../../../partials/job/JobSidebar';
import SortJobListing from './SortJobListing';
import JobListItem from '../../../partials/job/JobListItem';
import PaginationLoadMore from '../../../components/PaginationLoadMore';

import Image03 from '../../../images/company-icon-03.svg';
import { formatDateToShortDate } from '../../../utils/Utils';
import Button from '../../../components/Button';
// import Image01 from '../../../images/company-icon-05.svg';
// import Image02 from '../../../images/company-icon-06.svg';
// import Image04 from '../../../images/company-icon-07.svg';
// import Image05 from '../../../images/company-icon-08.svg';
// import Image06 from '../../../images/company-icon-01.svg';
// import Image07 from '../../../images/company-icon-02.svg';

function JobListing({ positions: items, activeItems, totalItems, onLoadMore, isLoading, hasNextPage, onChangeFilter }) {
  const [filter, setFilter] = useState('');

  const filteredJobs = items.filter((job) => {
    if (filter === '') return true;
    return (
      job.experience_type.toLowerCase().includes(filter.toLowerCase()) ||
      job.contract_type.toLowerCase().includes(filter.toLowerCase()) ||
      job.experience_level.toLowerCase().includes(filter.toLowerCase()) ||
      job.city.toLowerCase().includes(filter.toLowerCase())
    );
  });

  return (
    <div className="flex flex-col space-y-0 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-2 xl:flex-row xl:space-x-6 xl:space-y-0">
      {/* Sidebar */}
      <JobSidebar />
      {/* Content */}
      <div className="w-full">
        {/* Search form */}
        <div className="mb-5">
          <form className="relative">
            <label htmlFor="job-search" className="sr-only">
              Pesquisar
            </label>
            <input
              id="job-search"
              value={filter}
              onChange={(e) => {
                setFilter(e.currentTarget.value);
              }}
              className="form-input w-full pl-9 bg-white dark:bg-slate-800"
              type="search"
              placeholder="Pesquisar vaga por título ou palavra-chave"
            />
            <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
              <svg
                className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
              </svg>
            </button>
          </form>
        </div>

        {/* Jobs header */}
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm text-slate-500 dark:text-slate-400 italic">
            {activeItems ? `${activeItems} vagas ativas` : 'Você não tem vagas ativas'}
          </div>
          {/* Sort */}
          {filter ? (
            <Button onClick={() => setFilter('')} size="sm" variant="secondary">
              Limpar Filtro
            </Button>
          ) : (
            <SortJobListing
              onChange={(value) => {
                onChangeFilter((prev) => ({ ...prev, ...value }));
              }}
            />
          )}
        </div>

        {/* Jobs list */}
        <div className="space-y-2">
          {filteredJobs.length > 0 ? (
            filteredJobs
              .sort((a, b) => b.activeCandidatures - a.activeCandidatures)
              .map((item) => {
                return (
                  <JobListItem
                    {...item}
                    key={item.id}
                    id={item.id}
                    image={Image03}
                    role={item.experience_type}
                    link={`/positions/${item.id}`}
                    date={formatDateToShortDate(item.createdAt)}
                    type={item.status}
                    fav={item.bookmarked}
                  />
                );
              })
          ) : (
            <div className="text-center py-8 text-slate-500 dark:text-slate-400">
              Nenhuma vaga encontrada para o filtro aplicado.
            </div>
          )}
        </div>

        {/* Pagination */}
        {totalItems > items.length && !filter && (
          <div className="mt-6">
            <PaginationLoadMore hasNextPage={hasNextPage} isLoading={isLoading} onLoadMore={onLoadMore} />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(JobListing);
