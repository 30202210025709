import React, { createContext, useState, useEffect } from 'react';
import auth from '../services/auth/firebase.ts';
import { useQuery } from 'react-query';
import { getUser } from '../services/app.ts';
import Loading from '../../components/app/Loading.jsx';
import { Navigate, useLocation } from 'react-router-dom';
import { useEnums } from './useEnums.jsx';
import OpenAppError from '../../components/OpenAppError.jsx';

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const { pathname } = useLocation();
  const isAuthRoute = pathname.split('/').includes('auth') || pathname === '/';
  const isOnboardingRoute = pathname === '/onboarding';
  const { data: enumData, isError: isEnumError, refetch: refetchEnum, isLoading: isLoadingEnum } = useEnums();
  const [firebaseData, setFirebaseData] = useState({
    data: null,
    isFetched: false,
    isAuthenticated: false,
  });
  const userPhone = firebaseData?.data?.phoneNumber;
  const {
    data: user,
    isFetched: isUserFetched,
    isError,
    isFetching,
  } = useQuery(['user', userPhone], getUser, {
    retry: false,
    enabled: !!userPhone,
    staleTime: 1000 * 60 * 60 * 12, // 12 horas
    cacheTime: 1000 * 60 * 60 * 12,
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((data) => {
      setFirebaseData((prevState) => ({ ...prevState, data, isFetched: true, isAuthenticated: !!data }));
    });
    return () => unsubscribe();
  }, []);

  const { isFetched, data } = firebaseData;

  if (!isAuthRoute || !isFetched) {
    if (isEnumError) return <OpenAppError refetch={refetchEnum} isLoading={isLoadingEnum} />;
    if ((!isUserFetched && isError) || !isFetched || isFetching || isLoadingEnum) return <Loading />;
  }
  if (data && !data.displayName && !isOnboardingRoute) return <Navigate to="/onboarding" />;

  const { entity } = user || {};
  return (
    <UserContext.Provider
      value={{
        user,
        enumData,
        isLoadingEnum,
        isUserFetched,
        firebaseData,
        isFetching: !entity && isFetching,
        isCompany: entity === 'company',
        isCandidate: entity === 'candidate',
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
