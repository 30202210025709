import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../common/services/auth';

function CompanyOrCandidate({ display, onSubmit }) {
  const navigate = useNavigate();
  function handleFormChange(e) {
    e.preventDefault();
    // get the id of the selected radio button
    const entity = e.target.querySelector('input[name="radio-buttons"]:checked').id;
    onSubmit({ entity });
  }
  return (
    <div className="px-4 py-8" style={{ display: display ? 'block' : 'none' }}>
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
          Que bom ter você aqui! 🎉 <br />
        </h1>
        <h2 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-6">Como podemos te ajudar?</h2>
        {/* Form */}
        <form onSubmit={handleFormChange}>
          <div className="space-y-3 mb-8">
            <label className="relative block cursor-pointer">
              <input id="company" type="radio" name="radio-buttons" className="peer sr-only" defaultChecked />
              <div className="flex items-center bg-white text-sm font-medium text-slate-800 dark:text-slate-100 p-4 rounded dark:bg-slate-800 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm duration-150 ease-in-out">
                <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
                  <path className="text-indigo-500" d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z" />
                  <path className="text-indigo-300" d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z" />
                  <path className="text-indigo-200" d="M13 12.588v11l8.486-4.714A1 1 0 0 0 22 18V7.589l-9 4.999Z" />
                </svg>
                <span>
                  Quero <b>contratar</b> um profissional
                </span>
              </div>
              <div
                className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 dark:peer-checked:border-indigo-500 rounded pointer-events-none"
                aria-hidden="true"
              ></div>
            </label>
            <label className="relative block cursor-pointer">
              <input id="candidate" type="radio" name="radio-buttons" className="peer sr-only" />
              <div className="flex items-center bg-white text-sm font-medium text-slate-800 dark:text-slate-100 p-4 rounded dark:bg-slate-800 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm duration-150 ease-in-out">
                <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
                  <path className="text-indigo-500" d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z" />
                </svg>
                <span>
                  Quero <b>conseguir um emprego</b>
                </span>
              </div>
              <div
                className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 dark:peer-checked:border-indigo-500 rounded pointer-events-none"
                aria-hidden="true"
              ></div>
            </label>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-between">
              <button
                className="text-sm underline hover:no-underline"
                onClick={async () => {
                  await logout();
                  navigate('/auth/login');
                }}
                to="/auth/login"
              >
                &lt;- Voltar
              </button>
            </div>
            <button type="submit" className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto">
              Prosseguir -&gt;
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyOrCandidate;
