import React, { useState, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getPositions } from '../../../common/services/app';

import Sidebar from '../../../partials/Sidebar';
import FooterNav from '../../../partials/FooterNav';
import Section from '../components/Section';
import Header from '../../../partials/Header';
import EmptyState from './PositionsEmptyState';
import JobListing from './JobListing';
import NewPositionButton from './NewPositionButton';
import { showPromoModal } from '../components/PromoFirstPurchaseModal';

function Positions() {
  const [filters, setFilters] = useState('LAST');
  const memoizedFilters = useMemo(() => filters, [filters]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data, fetchNextPage, isFetched, hasNextPage, isFetching, ...query } = useInfiniteQuery({
    onSuccess: ({ pages }) => {
      const completeCandidates = pages[0].positions.find(({ status }) => status === 'CANDIDATES_REVIEW');
      if (completeCandidates) {
        showPromoModal(`/positions/${completeCandidates.id}`);
      }
    },
    queryKey: ['positions', memoizedFilters],
    queryFn: (params) => getPositions({ ...params, ...memoizedFilters }),
    keepPreviousData: true,
    getNextPageParam: ({ page: currentPage, totalItems }) => {
      return currentPage * 10 < totalItems ? currentPage + 1 : undefined;
    },
  });

  const { activeItems, totalItems } = data?.pages[0] || {};
  const positions = data?.pages.map((page) => page.positions).flat();

  const backgroundColor = isFetched && positions?.length === 0 ? '' : 'bg-slate-100';

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className={`grow ${backgroundColor} transition-all`}>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8 flex items-center justify-between sm:flex sm:justify-between sm:items-center">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Vagas ✨</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Add board button */}
                <NewPositionButton disabled={activeItems > 2} />
              </div>
            </div>
            <Section {...query} isEmpty={!positions?.length} EmptyFeedback={EmptyState}>
              <JobListing
                filterValue={memoizedFilters}
                positions={positions}
                hasNextPage={hasNextPage}
                onLoadMore={fetchNextPage}
                activeItems={activeItems}
                totalItems={totalItems}
                isLoading={isFetching}
                onChangeFilter={setFilters}
              />
            </Section>
          </div>
        </main>
        <FooterNav />
      </div>
    </div>
  );
}

export default Positions;
