import auth from './firebase';
import type { AxiosRequestConfig } from 'axios';
import { signOut, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
  }
}

export function generateRecaptcha(componentName: string) {
  window.recaptchaVerifier = new RecaptchaVerifier(auth, componentName, {
    size: 'invisible',
  });
  return window.recaptchaVerifier;
}

export function signWithPhone(phoneNumber: string) {
  return signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
}

export function logout() {
  return signOut(auth);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getCurrentUser(): any {
  return auth?.currentUser;
}

export async function refreshUser() {
  const user = getCurrentUser();
  if (user) {
    await user.reload();
  }
  return user;
}

export async function attachToken(config: AxiosRequestConfig) {
  const token = await getCurrentUser()?.getIdToken(true);
  if (config.headers && token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
}
