import React from 'react';

export default function UseTerms() {
  return (
    <div className="px-5 py-4">
      <div className="text-sm">
        <div className="space-y-2">
          <div className="text-sm space-y-6">
            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
                1. IDENTIFICAÇÃO DO APLICATIVO E RESPONSÁVEIS
              </div>
              <div className="space-y-2">
                <p>
                  O presente Termos de Uso rege as condições de utilização do aplicativo <strong>Trabalha AI</strong>,
                  de propriedade e responsabilidade de <strong>Trabalha AI Tecnologia LTDA</strong>.
                </p>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">2. USUÁRIOS-ALVO</div>
              <div className="space-y-2">
                <p>O aplicativo destina-se a:</p>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Candidatos:</strong> Pessoas que buscam oportunidades de emprego;
                  </li>
                  <li>
                    <strong>Empregadores:</strong> Empresas que buscam candidatos para preenchimento de vagas.
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
                3. JURISDIÇÃO E LEGISLAÇÃO APLICÁVEL
              </div>
              <div className="space-y-2">
                <p>O uso do aplicativo está sujeito à legislação brasileira, em especial:</p>
                <ul className="list-disc pl-5">
                  <li>Lei Geral de Proteção de Dados (LGPD);</li>
                  <li>Código de Defesa do Consumidor;</li>
                  <li>Marco Civil da Internet.</li>
                </ul>
                <p>
                  Para dirimir quaisquer controvérsias oriundas deste Termo, fica eleito o foro da Comarca de São Paulo,
                  Estado de São Paulo, Brasil.
                </p>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
                4. COLETA E USO DE DADOS PESSOAIS
              </div>
              <div className="space-y-2">
                <p>
                  <strong>Dados Coletados:</strong> São coletados dados como nome, telefone, endereço, CPF,
                  escolaridade, experiência profissional, habilidades e outros que se fizerem necessários para o
                  cadastro e a utilização do serviço.
                </p>
                <p>
                  <strong>Finalidade e Uso:</strong> Os dados são utilizados para possibilitar o cadastro, a busca de
                  vagas e o cruzamento de informações entre candidatos e empresas.
                </p>
                <p>
                  <strong>Armazenamento e Proteção:</strong> As informações serão armazenadas em servidores seguros,
                  protegidos por sistemas de criptografia e outras medidas de segurança.
                </p>
                <p>
                  <strong>Compartilhamento de Dados:</strong> Os dados dos usuários poderão ser compartilhados com
                  empresas anunciantes de vagas, exclusivamente mediante consentimento prévio dos titulares.
                </p>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">5. FUNCIONALIDADES PAGAS</div>
              <div className="space-y-2">
                <p>
                  <strong>Recursos Premium:</strong> Empresas interessadas em visualizar os dados dos candidatos deverão
                  adquirir acesso mediante pagamento.
                </p>
                <p>
                  <strong>Processo de Cobrança:</strong> O pagamento poderá ser realizado por meio de cartão de crédito
                  ou PIX, conforme as opções disponibilizadas pelo aplicativo.
                </p>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
                6. OBRIGAÇÕES E RESPONSABILIDADES DOS USUÁRIOS
              </div>
              <div className="space-y-2">
                <p>Os usuários comprometem-se a:</p>
                <ul className="list-disc pl-5">
                  <li>Fornecer informações verdadeiras, precisas e atualizadas;</li>
                  <li>Não divulgar ou inserir informações pessoais sensíveis de terceiros sem a devida autorização;</li>
                  <li>Observar as regras de conduta estabelecidas neste Termo;</li>
                  <li>Abster-se de utilizar sistemas automatizados para acessar ou manipular dados do aplicativo.</li>
                </ul>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
                7. RESPONSABILIDADES DO APLICATIVO
              </div>
              <div className="space-y-2">
                <p>
                  O <strong>Trabalha AI</strong> não se responsabiliza pela veracidade das informações fornecidas pelos
                  usuários, tampouco garante a contratação ou a efetivação de vínculos empregatícios.
                </p>
                <p>
                  As vagas de emprego anunciadas devem obedecer à legislação trabalhista vigente, sendo de inteira
                  responsabilidade das empresas anunciantes a veracidade e a conformidade das informações.
                </p>
                <p>
                  O aplicativo não realizará verificação ou validação dos anúncios de vagas, cabendo às empresas a
                  responsabilidade de manter seus anúncios em conformidade com a legislação.
                </p>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">8. PROPRIEDADE INTELECTUAL</div>
              <div className="space-y-2">
                <p>
                  As informações e conteúdos fornecidos pelos usuários são de sua exclusiva propriedade, não sendo
                  transferidos quaisquer direitos ao <strong>Trabalha AI</strong>.
                </p>
                <p>
                  Os usuários comprometem-se a respeitar os direitos autorais e de propriedade intelectual de terceiros,
                  assumindo total responsabilidade por quaisquer violações.
                </p>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">9. RESOLUÇÃO DE DISPUTAS</div>
              <div className="space-y-2">
                <p>
                  Eventuais conflitos entre usuários ou entre usuários e o aplicativo deverão ser inicialmente
                  resolvidos por meio de negociação direta.
                </p>
                <p>
                  Na ausência de um acordo, as partes poderão recorrer ao Poder Judiciário, elegendo o foro da Comarca
                  de São Paulo, Estado de São Paulo, Brasil.
                </p>
              </div>
            </div>

            <div>
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
                10. ENCERRAMENTO DE CONTA E TÉRMINO DO SERVIÇO
              </div>
              <div className="space-y-2">
                <p>
                  <strong>Encerramento pelo Usuário:</strong> Os usuários podem encerrar suas contas a qualquer momento,
                  sem a cobrança de custos adicionais.
                </p>
                <p>
                  <strong>Encerramento pelo Aplicativo:</strong> O <strong>Trabalha AI</strong> reserva-se o direito de
                  encerrar ou suspender o acesso de usuários que:
                </p>
                <ul className="list-disc pl-5">
                  <li>Descumprirem quaisquer das disposições destes Termos de Uso;</li>
                  <li>Praticarem condutas inadequadas ou ilícitas.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
