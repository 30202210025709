import { TbUserCancel } from 'react-icons/tb';

export default function CancelledPosition() {
  return (
    <>
      <header>
        <div className="flex items-center justify-between">
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-500">
            <TbUserCancel className="w-5 h-5 text-white" />
          </div>
        </div>
      </header>
      <div className="grow mt-3 pb-2">
        <div className="inline-flex text-slate-800 dark:text-slate-100 hover:text-slate-900 dark:hover:text-white mb-1">
          <h2 className="text-xl leading-snug font-semibold">Vaga cancelada</h2>
        </div>
        <div className="text-sm">
          Quando uma vaga é cancelada, os possíveis candidatos são notificados e nós encerramos o processo de seleção.
          Você pode abrir uma nova vaga a qualquer momento.
        </div>
      </div>
    </>
  );
}
