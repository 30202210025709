import React from 'react';
import { Link } from 'react-router-dom';

function Step({ index, active, disabled, onClick }) {
  const defaultStyle = 'flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold';
  const activeStyle = 'bg-indigo-500 text-white';
  const style = 'bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400';

  function handleStepClick() {
    onClick(index);
  }

  return (
    <li>
      <Link
        onClick={handleStepClick}
        className={`${defaultStyle} ${active ? activeStyle : style} ${disabled ? 'pointer-events-none' : 'cursor-pointer'}`}
      >
        {index + 1}
      </Link>
    </li>
  );
}

export default Step;
