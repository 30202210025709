import React, { useEffect } from 'react';
import { UserProvider } from './common/hooks/useUser';

import Routes from './Routes';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';

import './css/style.css';
import './charts/ChartjsConfig';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]); // triggered on route change

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <UserProvider>
        <Routes />
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
