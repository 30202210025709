import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';

function DropdownClassic({ id = 'dropdown-classic', value, options = [], onChange }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState('');

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      setSearch('');
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const { label } = options.find((option) => option.id === value) || {}

  return (
    <div className="relative">
      <button
        type="button"
        ref={trigger}
        className="btn w-full justify-between min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="flex items-center">
          <span className={`fi fi-${value} mr-1`}></span>
          <span className="overflow-hidden whitespace-nowrap overflow-ellipsis max-w-32">{label}</span>
        </span>
        <svg className="shrink-0 ml-1 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 dark:text-slate-300"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="px-2 py-1">
            <input
              id={id + 'filter'}
              value={search}
              placeholder="Pesquisar"
              className="form-input items-center w-full"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="h-full max-h-40 overflow-y-auto">
            {options
              .filter(({ label }) => label.toLowerCase().includes(search.toLowerCase()) || !search)
              .map((option) => {
                return (
                  <button
                    type="button"
                    key={option.id}
                    tabIndex="0"
                    className={`flex items-center w-full hover:bg-slate-50 hover:dark:bg-slate-700/20 py-1 px-3 cursor-pointer ${
                      option.id === value && 'text-indigo-500'
                    }`}
                    onClick={() => {
                      onChange(option);
                      setDropdownOpen(false);
                    }}
                  >
                    <svg
                      className={`shrink-0 mr-2 fill-current text-indigo-500 ${option.id !== value && 'invisible'}`}
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                    >
                      <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                    </svg>
                    <span className={`fi fi-${option?.id} mr-1`}></span>
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">{option.label}</span>
                  </button>
                );
              })}
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownClassic;
