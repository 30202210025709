import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

export default function SendCandidateMessage({ phone, positionName, onClick }) {
  const baseUrl = 'https://wa.me';
  phone = phone?.replace(/\+|\s|\(|\)|-/g, '');
  const text = `Olá, você foi recomendado pela Trabalha Aí para uma vaga de ${positionName} na minha empresa. Podemos conversar?`;
  const encodedText = encodeURIComponent(text);
  const href = `${baseUrl}/${phone}?text=${encodedText}`;

  const phoneLink = {
    target: '_blank',
    rel: 'noreferrer',
    href,
  };

  const params = !phone ? { onClick } : phoneLink;
  return (
    <a
      className="block flex-1 text-center text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 font-medium px-3 py-4 cursor-pointer"
      {...params}
    >
      <div className="flex items-center justify-center">
        <FaWhatsapp className="w-5 h-5 fill-current shrink-0 mr-2" />
        <span>Enviar mensagem</span>
      </div>
    </a>
  );
}
