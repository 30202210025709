import React, { useContext } from 'react';
import Tooltip from '../../../../components/Tooltip';
import { UserContext } from '../../../../common/hooks/useUser';

export default function FeedbackInfo({ feedback, feedbackComment }) {
  const { enumData: data } = useContext(UserContext);
  const finalFeedback = data?.feedbacks?.find(({ id }) => id === feedback);
  if (!finalFeedback) return null;
  const review = finalFeedback.description + (feedbackComment ? ` (${feedbackComment})` : '');

  return (
    <div className="flex items-center space-x-2">
      <Tooltip size="lg" position="right" bg="dark">
        <div className="text-xs">
          <div className="font-medium text-slate-200 mb-0.5">Sua avaliação:</div>
          <div className="text-slate-400">{review}</div>
        </div>
      </Tooltip>
      <div className="text-sm font-medium text-slate-500 dark:text-slate-400">
        Candidato avaliado: {finalFeedback.feedback}
      </div>
    </div>
  );
}
