import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Select from '../../../components/Select';
import { UserContext } from '../../../common/hooks/useUser';

function CandidateTest({ display, onSubmit, onPrev }) {
  const { enumData: data } = useContext(UserContext);
  const options = data?.testQuestions;

  function handleFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataArray = Array.from(formData.entries());

    const Test = formDataArray.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    onSubmit({ Test });
  }

  return (
    <div className="px-4 py-8 max-w-md mx-auto" style={{ display: display ? 'block' : 'none' }}>
      <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Conhecimentos gerais</h1>
      <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">
        Agora vamos fazer 5 perguntas rápidas para entender um pouco mais sobre o seus conhecimentos gerais.
      </p>
      <div className="max-w-md mx-auto">
        {/* htmlForm */}
        <form onSubmit={handleFormSubmit}>
          <div className="space-y-4 mb-8">
            {/* Position */}
            {options?.map(({ id, question, options }) => (
              <div key={id}>
                <label className="block text-sm font-medium mb-1 flex-nowrap" htmlFor={id}>
                  {question} <span className="text-rose-500">*</span>
                </label>
                <Select id={id} name={id} required options={options} />
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between">
            <Link className="text-sm underline hover:no-underline" onClick={onPrev}>
              &lt;- Voltar
            </Link>
            <button type="submit" className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto">
              Prosseguir -&gt;
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CandidateTest;
