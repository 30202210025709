import { useQuery } from 'react-query';
import { getEnums } from '../services/app';

export function useEnums() {
  return useQuery('enums', getEnums, {
    keepPreviousData: true,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
