import axios from './axios';

export function syncUser() {
  return axios.post('/users/sync');
}

export function refuseCandidate({ positionId, candidateId, refusal }) {
  return axios.post(`positions/${positionId}/candidates/${candidateId}/refuse`, { refusal });
}

export function sendCandidateFeedback({ positionId, candidateId, feedback }) {
  return axios.post(`positions/${positionId}/candidates/${candidateId}/feedback`, { feedback });
}

export function getUser() {
  return axios.get('/users');
}

export function deleteUser() {
  return axios.delete('/users');
}

export function readNotifications() {
  return axios.post('/users/notifications/read');
}

export function getUserPaymentMethods() {
  return axios.get('/users/payment-methods');
}

export function getEnums() {
  return axios.get('/enums');
}

export function getZipCodes({ queryKey }) {
  const cep = queryKey[1];
  return axios.get(`/zipcodes/${cep}`);
}

export function createCompany(data) {
  return axios.post('/users/onboarding/company', data);
}

export function createCandidate(data) {
  return axios.post('/users/onboarding/candidate', data);
}

export function createPosition(data) {
  return axios.post('/positions', data);
}

export function getPosition({ queryKey }) {
  const id = queryKey[1];
  return axios.get(`/positions/${id}`);
}

export function deletePosition({ positionId, refusal }) {
  return axios.delete(`/positions/${positionId}`, {
    data: { refusal },
  });
}

export function closePosition({ positionId, refusal }) {
  return axios.put(`/positions/${positionId}/close`, {
    data: { refusal },
  });
}

export function releasePosition(id) {
  return axios.post(`/positions/${id}/release`);
}

export function payAndReleasePosition(data) {
  const { id: positionId, data: card } = data;
  return axios.post(`/positions/${positionId}/pay`, card);
}

export function getPositions({ createdAt, pageParam: page, status }) {
  return axios.get('/positions', { params: { createdAt, page, status } });
}

export function getTransactions() {
  return axios.get('/wallets/transactions');
}

export function getPositionCandidatures({ queryKey }) {
  const id = queryKey[1];
  return axios.get(`/positions/${id}/candidatures`);
}

export function getBestPosition() {
  return axios.get('/jobs/best-position');
}

export function createApplication(data) {
  return axios.post('/applications', data);
}

export async function tokenizeCard(card) {
  const APP_ID = process.env.PAGARME_PUBLIC_KEY;
  try {
    const response = await fetch(
      `https://api.mundipagg.com/core/v1/tokens?appId=${APP_ID}&type=checkout-transparent-multi-card`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ card }),
      },
    );

    const data = await response.json();
    if (!data.id) throw new Error(data);
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
}
