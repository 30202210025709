import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { getZipCodes } from '../../../common/services/app.ts';

function Address({ id = 'address', value, isLoading: isMutating, hideActions, onPrev, onSubmit }) {
  const formRef = useRef(null);
  const [address, setAddress] = useState(value);

  useEffect(() => {
    setAddress(value);
  }, [value]);

  const { isLoading: isFetchingCep } = useQuery(['cep', address?.cep], getZipCodes, {
    select: (data) => ({ ...data, cep: data.zipcode }),
    onSuccess: (data) => {
      setAddress((prevState) => ({ ...prevState, ...data }));
      formRef.current.elements.number.focus();
    },
    onError: () => {
      toast.error('CEP não encontrado');
    },
    enabled: !!address?.cep && address?.cep.length === 8,
  });

  function cleanCep(value) {
    return value.replace(/\D/g, '');
  }

  function handleFormChange({ currentTarget }) {
    let { name, value } = currentTarget;
    if (name === 'cep') value = cleanCep(value);
    setAddress((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    onSubmit(address);
  }

  const isLoading = isFetchingCep || isMutating;

  return (
    <form
      id={id}
      disabled={isFetchingCep}
      ref={formRef}
      className={isLoading ? 'opacity-50' : ''}
      onSubmit={handleFormSubmit}
    >
      <div className="space-y-4">
        {/* City and Postal Code */}
        <div className="flex space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1" htmlFor="cep">
              CEP <span className="text-rose-500">*</span>
            </label>
            <Input
              required
              id="cep"
              name="cep"
              maxLength="8"
              minLength="8"
              type="text"
              inputMode="numeric"
              value={address?.cep}
              onChange={handleFormChange}
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1" htmlFor="city">
              Cidade <span className="text-rose-500">*</span>
            </label>
            <Input value={address?.city} onChange={handleFormChange} disabled id="city" type="text" />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1" htmlFor="state">
              Estado <span className="text-rose-500">*</span>
            </label>
            <Input disabled required value={address?.state} onChange={handleFormChange} id="state" type="text" />
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1" htmlFor="street">
              Rua/Avenida <span className="text-rose-500">*</span>
            </label>
            <Input required value={address?.street} onChange={handleFormChange} id="street" type="text" />
          </div>
        </div>
        {/* Number and Complement */}
        <div className="flex space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1" htmlFor="neighborhood">
              Bairro <span className="text-rose-500">*</span>
            </label>
            <Input required value={address?.neighborhood} onChange={handleFormChange} id="neighborhood" type="text" />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1" htmlFor="number">
              Número <span className="text-rose-500">*</span>
            </label>
            <Input
              required
              value={address?.number}
              onChange={handleFormChange}
              id="number"
              type="text"
              inputMode="numeric"
            />
          </div>
        </div>
      </div>

      <button
        id="submit-hidden-button"
        type="submit"
        className="hidden"
        onClick={(e) => {
          e.preventDefault();
          handleFormSubmit(e);
        }}
      />

      {!hideActions && (
        <div className="flex items-center justify-between mt-8">
          <Link className="text-sm underline hover:no-underline" onClick={onPrev}>
            &lt;- Voltar
          </Link>
          <Button disabled={isLoading} loading={isMutating} className="text-white ml-3 whitespace-nowrap min-w-[6rem]">
            Prosseguir -&gt;
          </Button>
        </div>
      )}
    </form>
  );
}

export default Address;
