import React from 'react';
import Tooltip from '../../../components/Tooltip';
import { IoCloseSharp } from 'react-icons/io5';

export default function RefuseCandidate({ onClick, disabled }) {
  const disabledClass =
    'disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed shadow-none';
  return (
    <button
      aria-controls="feedback-modal"
      disabled={disabled}
      className={`block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group justify-items-center ${disabled ? disabledClass : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick(true);
      }}
    >
      <Tooltip
        size="lg"
        position="top"
        bg="dark"
        content={
          <div className="flex items-center justify-center">
            <svg
              className={`w-5 h-5 fill-current text-slate-400 dark:text-rose-600 shrink-0 mr-1 ${disabled ? 'text-slate-400 dark:text-slate-600 group:hover:text-slate-400' : 'group-hover:text-rose-500'}`}
              viewBox="0 0 14 14"
            >
              <IoCloseSharp />
            </svg>
            <span>Recusar candidato</span>
          </div>
        }
      >
        {disabled && <div className="text-xs">Você já atingiu o limite de candidatos recusados para esta vaga.</div>}
      </Tooltip>
    </button>
  );
}
