import React, { useState } from 'react';

import DropdownSort from '../../../components/DropdownSort';
export default function SortJobListing({ onChange }) {
  const [value, setValue] = useState('LAST');
  const options = [
    { id: 'LAST', label: 'Recentes', value: { createdAt: 'DESC' } },
    { id: 'FIRST', label: 'Antigos', value: { createdAt: 'ASC' } },
  ];

  function onChangeOption(selectedId) {
    setValue(selectedId);
    onChange(options.find(({ id }) => id === selectedId).value);
  }

  return (
    <div className="text-sm text-end">
      <span>Ordenar por </span>
      <DropdownSort value={value} options={options} align="right" onChange={onChangeOption} />
    </div>
  );
}
