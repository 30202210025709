import React, { useState } from 'react';
import ModalBlank from '../../components/ModalBlank';
import { useMutation } from 'react-query';
import { deleteUser } from '../../common/services/app';
import { toast } from 'react-toastify';
import { logout } from '../../common/services/auth';
import { useNavigate } from 'react-router-dom';

export default function DeleteAccount() {
  const navigate = useNavigate();
  const [dangerModalOpen, setDangerModalOpen] = useState(false);

  const { mutate, isLoading } = useMutation(mutateFn, {
    onSuccess: async () => {
      await logout();
      navigate('/auth/login');
    },
  });

  function mutateFn() {
    return toast.promise(deleteUser(), {
      pending: 'Apagando conta...',
      success: 'Sua conta foi apagada com sucesso! Até logo!',
      error: 'Ocorreu um erro ao apagar sua conta. Tente novamente ou entre em contato com o suporte.',
    });
  }

  return (
    <section>
      <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Gestão da conta</h3>
      <ul>
        <li className="flex justify-between items-center py-3 border-b border-slate-200 dark:border-slate-700">
          <div>
            <div className="text-slate-800 dark:text-slate-100 font-semibold">Apagar conta</div>
            <div className="text-sm">
              Ao apagar sua conta, você deixará de ser contactado por empresas e seus dados serão removidos.
            </div>
          </div>
          {/* Right */}
          <div className="flex items-center ml-4">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setDangerModalOpen(true);
              }}
              aria-controls="delete-account-modal"
              className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-rose-500"
            >
              Apagar conta
            </button>
            <ModalBlank id="delete-account-modal" modalOpen={dangerModalOpen} setModalOpen={setDangerModalOpen}>
              <div className="p-5 flex space-x-4">
                {/* Icon */}
                <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100 dark:bg-rose-500/30">
                  <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                  </svg>
                </div>
                {/* Content */}
                <div>
                  {/* Modal header */}
                  <div className="mb-2">
                    <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">
                      Tem certeza que deseja apagar sua conta?
                    </div>
                  </div>
                  {/* Modal content */}
                  <div className="text-sm mb-10">
                    <div className="space-y-2">
                      <p>
                        Ao apagar sua conta, você deixará de ser contactado por empresas e seus dados serão removidos.
                      </p>
                    </div>
                  </div>
                  {/* Modal footer */}
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                      disabled={isLoading}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDangerModalOpen(false);
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={mutate}
                      disabled={isLoading}
                      className="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
                    >
                      Sim, apagar conta
                    </button>
                  </div>
                </div>
              </div>
            </ModalBlank>
          </div>
        </li>
      </ul>
    </section>
  );
}
