import React from 'react';
import Button from './Button';
import { IoAlertCircleOutline as AlertCircle } from 'react-icons/io5';
import { FiRefreshCw as RefreshCw } from 'react-icons/fi';

export default function OpenAppError({ refetch, isLoading }) {
  return (
    <div className="flex min-h-[100vh] items-center justify-center p-4 md:p-8">
      <div className="w-full max-w-md p-2 rounded-lg bg-background">
        <div className="text-center mb-6">
          <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-amber-50 text-amber-500">
            <AlertCircle className="h-8 w-8" />
          </div>
          <h2 className="text-xl md:text-2xl font-medium">Oops! Tivemos um pequeno problema.</h2>
        </div>
        <div className="text-center mb-6">
          <p className="text-muted-foreground">Algo deu errado mas não se preocupe, você pode tentar novamente.</p>
        </div>
        <div className="flex justify-center">
          <Button onClick={refetch} disabled={isLoading} className="gap-2 px-6">
            <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
            {isLoading ? 'Carregando...' : 'Tentar novamente'}
          </Button>
        </div>
      </div>
    </div>
  );
}
